<template>
    <div>

      <cloud-header title="Καλωσόρισες στο Athlisis Cloud 👋" subtitle="Ένα μέρος όπου όλοι οι διατροφολόγοι του athlisis μπορούν να μοιράζονται εύκολα διατροφικά πλάνα, τρόφιμα, συνταγές, πρότυπα γεύματα."/>


      <cloud-popular-listings-card></cloud-popular-listings-card>

    </div>

</template>

<style lang="scss">
</style>

<script>
import CloudHeader from "@components/cloud/CloudHeader";
import CloudPopularListingsCard from "@components/cloud/CloudPopularListingsCard";

export default{

  components: {CloudHeader, CloudPopularListingsCard},


  mounted(){
  },

  data(){
    return{
    }
  },

  methods:{

  }
}
</script>
